<template>
    <div>
        <br />
        <div class="box container">
            <h3>Create Event</h3> 
            <hr />
             <div class="form-group">
    <label >Opponent Name: </label>
    <input type="text"  class="form-control" v-model="opponent"  placeholder="Opponent Name">
  </div>  <br />
  <p>Date:</p> 
  <datepicker  v-model="date"></datepicker>

  <br />
  
          <button @click="createEvent"  class="btn  btn-success" >Create Event</button>

        </div>
        <br /><br />
    </div>
</template>
<script>

import firebase from "firebase";
require("firebase/firestore");
var db = firebase.firestore();
import Datepicker from 'vuejs-datepicker';

export default {
      name: "AddEvent",
      components:{
          Datepicker
      },
      data() {
    return {
        opponent: "",
        date: new Date(),
     user: undefined

    }
      },
      mounted: function(){
               firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
     
      } else {
        this.user = null;
      }
    });
      },
      methods:{

   async   createEvent(){
      if (this.opponent.length == 0){ return}
var result = await db.collection("Events").add({
    author_uid: this.user.uid,
    can_edit: [this.user.uid],
    team_id: this.$route.params.team_id,
    opponent: this.opponent,
    date: new Date().getTime(),
    clips: []

})
console.log(result)
this.$router.push('/add/'+result.id)
        },
      }
}
</script>

<style>
.box{
    border-radius: 1em;
    background-color: white;
    padding: 1em;
    text-align: left;
}
.vdp-datepicker input{
  width:100%;
  padding: 0.4em;
  border: 1px solid #ccc;
}

</style>